
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCheckbox,
  IonContent,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  useIonAlert,
} from '@ionic/react';
import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import CustomInput, { SpecialInput } from '../components/InputContainer'

import {Volunteer} from '../model/User'

import {Api} from '../data/api';
import Header from '../components/Header';

const VolunteerPage: React.FC<{api:Api }> = ({api}) => {

  const [presentAlert] = useIonAlert();
  
  const defaultValues = {
    photography_consent: api.volunteer?.photography_consent || true,
  };

  const year = new Date().getFullYear();

  const { register, control, handleSubmit, formState: { errors } } = useForm({
    defaultValues});

  const onSubmit = async (data: any) => {
    
    const response = await api.saveVolunteer(data);
    if(response?.status !== 200){
      const $message = response.message ? response.message : 'Error saving, please check inputs and try again';
      presentAlert($message, [{ text: 'Ok' }]);
      
    }
  };
  
  return (
    <IonPage id="view-message-page">
      <Header api={api} title='Volunteer'/>

      <IonContent fullscreen>
      
      <form onSubmit={handleSubmit(onSubmit)}>
      <IonCard>
        <IonCardHeader>
          <IonCardTitle>Photography</IonCardTitle>
        </IonCardHeader>
          <IonCardContent>
          Each year we have a professional photographer 
		take random photos of Stable volunteers in action. 
		Please tick the box below indicating that you give permission for 
		The Stable to use any such photographs for publicity purposes.
    <SpecialInput type="checkbox" 
          inputText="Photography Consent" 
          field='photography_consent'
          control={control}
          error={errors.photography_consent} 
          register={register("photography_consent")} ></SpecialInput>
          </IonCardContent>
          </IonCard>

          <IonCard>
        <IonCardHeader>
          <IonCardTitle>Indemnity & Code of Conduct</IonCardTitle>
        </IonCardHeader>
          <IonCardContent>
          <p>
		I am willing to participate in the event Stable NQ.
		I understand the nature of the activities at the event and that risks 
		arise during these activities. I hereby authorise the leader manager or 
		leader in charge of the particular activity in which I am involved 
		to consent where it is impractical to communicate with me or my next of kin, 
		to my receiving medical treatment as the leader and qualified medical practitioner 
		may deem necessary at any time during the event. 
		I accept responsibility for payment of all expenses associated with any treatment.</p> 
		<p>
		I commit myself to excellence, courtesy and responsibility in all areas 
		of my involvement and to serve with honesty, integrity and humility.</p> 
		
		<p>I will read the 'volunteer info manual' which will be available at the training days 
		and on line from the 26th November and agree to follow the information provided in it.</p>
		
		<p>If you agree to the above paragraphs, please click 'I Agree' at the bottom of this page to sign up.</p>

    <p className={api.user?.birth_year && year - api.user?.birth_year < 18 ? 'warning':''}>If you are under 18 you must have your parent's or gardian's permission.</p>
	
		<p>If you require further info please contact the area leader for the areas you wish to be involved in.  
		Contact details are found in the volunteer information manual. </p>

          </IonCardContent>
          </IonCard>
          <IonButton expand="block" type="submit" className="ion-margin-top">
          I Agree
          </IonButton>
          </form>
      </IonContent>
    </IonPage>
  );
}

export default VolunteerPage;
