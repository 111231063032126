import { useState, useEffect } from "react";

import { Storage } from '@ionic/storage';

import User, {VolunteerLog, MainUser, UserErrors, Volunteer, Job, Shift, Area, Roster, Event} from '../model/User';

const API = process.env.REACT_APP_SERVER_URL;
const STORE = 'SOTS_vrs_';

export interface Token{
    id: number;
    token: string;
    pass: string;
    expires?: number;
}

export interface JobShiftVols{
  area_id: number;
  job_id: number;
  shift_id: number;
  count: number;
}

export interface HTTPResponce{
  status: number;
  message: string;
}

export interface LogsResponce extends HTTPResponce{
  logs: VolunteerLog[]
}

export interface VolunteersResponse extends HTTPResponce{
  volunteers: User[]
}

export interface VolunteerResponse extends HTTPResponce{
  volunteer: Volunteer
}

export interface JobsResponse extends HTTPResponce{
  jobs: Job[],
  jobvols: any,
  jobshiftvols: any,
  areaShifts: AreaShift
}


export interface ShiftsResponse extends HTTPResponce{
  shifts: Shift[],
}

export interface UserResponse extends HTTPResponce{
  user: MainUser,
  volunteer: Volunteer,
  errors: UserErrors
}

export interface AuthResponce extends HTTPResponce{
  user?: MainUser | null;
  id?: string | null;
  token?: Token | null;
  pass?: string | null;
  usernames?: string[];
}


export interface AreaShift{
  [index: number]: Shift[]
}


export interface Api{
    isAuthed: boolean;
    online: boolean;
    loading: boolean;
    initalised: boolean;
    agree: boolean;
    user: MainUser | undefined;
    volunteer: Volunteer | undefined;
    event: Event | undefined;
    jobshiftvols: JobShiftVols[] | undefined;
    jobvols: any;
    jobs: Job[];
    areas: Map<string, Job[]> | undefined;
    getJobs: () => Promise<boolean>;
    setUsernames:(usernames: string[]) => void;
    saveUser: (user: User) => Promise<UserResponse>;
    saveVolunteer: (volunteer: Volunteer) => Promise<VolunteerResponse>;
    rosterOn: ( shift: Shift, area?: Area, job?: Job) => Promise<VolunteerResponse>;
    rosterOff: ( roster: Roster) => Promise<VolunteerResponse>;
    clearAgree: () => void;
    sendCode: (code:string,tmpToken: Token | undefined) => Promise<boolean>;
    volunteerLookup: (name:string) => Promise<boolean>;
    sendEmail: (email: string, username: string) => Promise<AuthResponce>;
    log: (type: string, user_id: number) => Promise<AuthResponce>;
    signOut: (theUser: User) => Promise<LogsResponce>;
    signIn:  (theUser: User) => Promise<LogsResponce>;
    logOff: () => void;
    getLogs: () => Promise<boolean>;
    emailToken: Token | undefined;
    cancelEmail: () => void;
    usernames: string[];
    volunteers: User[];
    setVolunteers: (vols: User[]) => void;
    logs: VolunteerLog[];
    getShifts: (area_id: number) => Promise<Shift[] | boolean>;
    setAltUser: (user_id: number | undefined) => void;
    altUser: number | undefined;
    areaShifts: any;
    isSearching: boolean;
}

export const useServerConnection = () => {
    //Required for auth
    const [token, setToken] = useState<Token>();
    const [initalised, setInitalised] = useState<boolean>(false);
    const [emailToken, setEmailToken] = useState<Token>();
    const [isAuthed, setIsAuthed] = useState<boolean>(false);
    const [isSearching, setIsSearching] = useState<boolean>(false);

    const [online, setOnline] = useState<boolean>(true);
    //Used to show loading indicator - stops double submit
    const [loading, setLoading] = useState<boolean>(false);
    const [loaded, setLoaded] = useState<boolean>(false);
    //Agree to TCs
    const [agree, setAgree] = useState(false);

    //Local data
    const [user, _setUser] = useState<MainUser>();
    const [logs, setLogs] = useState<VolunteerLog[]>([]);
    const [volunteers, setVolunteers] = useState<User[]>([]);
    const [usernames, setUsernames] = useState<string[]>([]);
    const [volunteer, _setVolunteer] = useState<Volunteer|undefined>(undefined);
    const [event, setEvent] = useState<Event>();
    const [jobs, setJobs] = useState<Job[]>([]);
    const [jobshiftvols, setJobshiftvols] = useState<JobShiftVols[]>();
    const [jobvols, setJobvols] = useState();
    const [areaShifts, setAreaShifts] = useState<AreaShift | undefined>(undefined);
    const [areas, setAreas] = useState<Map<string, Job[]> | undefined>(undefined);
    const [altUser, _setAltUser] = useState<number|undefined>(undefined);


    const setAltUser = async (tmp: number | undefined)=>{
      console.log('setAltUser',tmp);
      await store.set(`${STORE}alt`,tmp);
      _setAltUser(tmp);
    }

    const setVolunteer = async (tmp: Volunteer | undefined)=>{
      await store.set(`${STORE}volunteer`,tmp);
      _setVolunteer(tmp);
    }

    const setUser = async (tmp: MainUser)=>{
      await store.set(`${STORE}user`,tmp);
      _setUser(tmp);
    }

    //Setup offline storage
    const store = new Storage();
    store.create();

    useEffect(() => {
      window.addEventListener("offline", () => {
        setOnline(false);
      });
      window.addEventListener("online", () => {
        setOnline(true);
      });
  
      return () => {
        window.removeEventListener("offline", () => {
          setOnline(false);
        });
        window.removeEventListener("online", () => {
          setOnline(true);
        });
      };
    }, []);

    const groupBy = <K, V>(list: Array<V>, keyGetter: (input: V) => K): Map<K, Array<V>> => {
      const map = new Map<K, Array<V>>();
      list.forEach((item) => {
           const key = keyGetter(item);
           const collection = map.get(key);
           if (!collection) {
               map.set(key, [item]);
           } else {
               collection.push(item);
           }
      });
      return map;
  }

    useEffect(() => {
           
      if(jobs){
        setAreas(groupBy(jobs, job => job.area.title));
      }else{
        setAreas(undefined);
      }
    }, [jobs]);

    /*
    useEffect(() => {
      

      api.areas?.forEach( (jobs,key) => {
        console.log(key);
      });
    }, [areas]);*/

    //On first load
    useEffect(() => {
        console.log('API useEffect');
        (async function getStorage() {
          //Load all data from local storage
          console.log('Load from local storage');

          const tmpToken:Token = await store.get(`${STORE}token`);
          setToken(tmpToken);

          const tmpEmailToken:Token = await store.get(`${STORE}email_token`);
          setEmailToken(tmpEmailToken);
          
          const tmpUser = await store.get(`${STORE}user`);
          _setUser(tmpUser);

          const tmpVolunteer = await store.get(`${STORE}volunteer`);
          _setVolunteer(tmpVolunteer);

          const tmpAgree = await store.get(`${STORE}agree`);
          setAgree(tmpAgree ? tmpAgree : false);

          const tmpAlt = await store.get(`${STORE}alt`);
          _setAltUser(tmpAlt ? tmpAlt : undefined);

          setLoaded(true);
    
        })();
      }, []);

      useEffect(() => {
        
        if(!online || !loaded) return;//Wait for loaded
        console.log('API: token set check auth');
        if(token){
            //Try authencate with token, 
            (async function inlineAsync() {
                const result = await checkAuthed(token);
                if(result){
                    setIsAuthed(true);
                }else{
                    //store.set(`${STORE}token`,undefined);
                    setToken(undefined);
                }

                setInitalised(true);
            })();
        }else{
          setInitalised(true);
        }
      }, [loaded, online, token, altUser]);//When altUser changes refresh


      useEffect(() => {
        if(!online || !isAuthed || user?.group_id === 1) return;
        
        console.log('Get Logs');
        
        getLogs();

      }, [isAuthed]);

      const logOff = () => {
        console.log('logOff');
        store.clear();

        window.location.reload();
      };

      const clearAgree = () => {
        console.log('clearAgree');
        store.set(`${STORE}agree`,true);
        setAgree(true);
      };

      const getJobs = async () => {
        console.log("getJobs");
        setLoading(true);
        try {
          const response = await fetch(`${API}/jobs.json`,{
            headers: {
              'Content-Type': 'application/json'
            }
          });
          
          if (!response.ok) { 
            throw Error(response.statusText);
          }
          const data: JobsResponse = await response.json();
          if(data?.status === 200 && data.jobs){

            setJobs(data.jobs);

            if(data.jobshiftvols){
              setJobshiftvols(data.jobshiftvols);
            }

            if(data.areaShifts){
              setAreaShifts(data.areaShifts);
            }

            if(data.jobvols){
              setJobvols(data.jobvols);
            }

            setLoading(false);
            return true;
          }

        } catch (error) {
          console.log(error);
        }
      
        setLoading(false);
        console.log("getJobs",'Failed');
        return false;
      };

      const getShifts = async () => {
        console.log("getShifts");
        setLoading(true);
        try {
          const response = await fetch(`${API}/rosters/shifts/${volunteer?.event_id}.json`,{
            headers: {
              'Content-Type': 'application/json'
            }
          });
          
          if (!response.ok) { 
            throw Error(response.statusText);
          }
          const data: ShiftsResponse = await response.json();
          if(data?.status === 200 && data.shifts){

            setLoading(false);
            return data.shifts;
          }

        } catch (error) {
          console.log(error);
        }
      
        setLoading(false);
        console.log("getShifts",'Failed');
        return false;
      };

      const volunteerLookup = async (name: string) => {
        console.log("volunteerLookup", name);
        if(!token) return false;
        setIsSearching(true);
        
        try {
          const response = await fetch(`${API}/auth/volunteers.json?q=${name}`,{
            headers: {
              'Content-Type': 'application/json',
              'Authorization': JSON.stringify({bob: 'Hi',alt: altUser, id: token.id, token: token.token, pass: token.pass}),
            }
          });
          
          if (!response.ok) { 
            throw Error(response.statusText);
          }
          const data: VolunteersResponse = await response.json();
          if(data?.status === 200 && data.volunteers){

            setVolunteers(data.volunteers);
            setIsSearching(false);
            return true;
          }

        } catch (error) {
          console.log(error);
        }
      
        console.log("volunteerLookup",'Failed');
        setIsSearching(false);
        return false;
      };

      const getLogs = async () => {
        console.log("getLogs");
        if(!token) return false;
        
        try {
          const response = await fetch(`${API}/auth/current.json`,{
            headers: {
              'Content-Type': 'application/json',
              'Authorization': JSON.stringify({bob: 'Hi',alt: altUser, id: token.id, token: token.token, pass: token.pass})
            }
          });
          
          if (!response.ok) { 
            throw Error(response.statusText);
          }
          const data = await response.json();
          if(data?.status === 200){

            if(data.logs){
              setLogs(data.logs);
            }

            
            return true;
          }

        } catch (error) {
          console.log(error);
        }
      
        
        console.log("getLogs",'Failed');
        return false;
      };

      const checkAuthed = async (tmpToken: Token) => {
        console.log("checkAuthed",tmpToken);
        setLoading(true);
        try {
          const response = await fetch(`${API}/auth.json`,{
            headers: {
              'Content-Type': 'application/json',
              'Authorization': JSON.stringify({bob: 'Hi',alt: altUser, id: tmpToken.id, token: tmpToken.token, pass: tmpToken.pass})
            }
          });
          
          if (!response.ok) { 
            throw Error(response.statusText);
          }
          const data = await response.json();
          if(data?.status === 200 && data.user){
            console.log("checkAuthed",'Success');

            if(data.user){
              setUser(data.user);
            }

            if(data.volunteer){
              setVolunteer(data.volunteer);
            }else{
              setVolunteer(undefined);
            }

            if(data.event){
              setEvent(data.event);
            }

            setLoading(false);
            return true;
          }

        } catch (error) {
          console.log(error);
        }
      
        setLoading(false);
        console.log("checkAuthed",'Failed');
        return false;
      };

      const signOut = async (theUser: User) => {
        console.log("signOut", theUser);
        if(!token || !isAuthed || user?.group_id === 1) return <LogsResponce>{
          status: 500,
          message: 'Not authenticated'
        };

        let response = <LogsResponce>{
          status: 500,
          message: 'Something went wrong, please try again.'
        }

        setLoading(true);

        try {
          const httpResponse = await fetch(`${API}/auth/current.json`,{
            headers: {
              'Content-Type': 'application/json',
              'Authorization': JSON.stringify({bob: 'Hi',alt: altUser, id: token.id, token: token.token, pass: token.pass})
            },
            method: 'POST',
            body: JSON.stringify({
              user_id: theUser.id,
              type: 'Out'
            }),//TODO: Add gps to note??
          });
          
          if (!httpResponse.ok) {
            throw Error(httpResponse.statusText);
          }

          response = await httpResponse.json();
          if(response.status === 200){
            console.log("signOut",'Success');

            if(response.logs){
              setLogs(response.logs);
            }

            setLoading(false);
            return response;
          }

        } catch (error) {
          console.log(error);
        }
      
        setLoading(false);
        console.log("signOut",'Failed');
        return response;
      };


      const signIn = async (theUser: User) => {
        console.log("SignIn", theUser);
        if(!token || !isAuthed || user?.group_id === 1) return <LogsResponce>{
          status: 500,
          message: 'Not authenticated'
        };

        let response = <LogsResponce>{
          status: 500,
          message: 'Something went wrong, please try again.'
        }

        setLoading(true);

        try {
          const httpResponse = await fetch(`${API}/auth/current.json`,{
            headers: {
              'Content-Type': 'application/json',
              'Authorization': JSON.stringify({bob: 'Hi',alt: altUser, id: token.id, token: token.token, pass: token.pass})
            },
            method: 'POST',
            body: JSON.stringify({
              user_id: theUser.id,
              type: 'In'
            }),//TODO: Add gps to note??
          });
          
          if (!httpResponse.ok) {
            throw Error(httpResponse.statusText);
          }

          response = await httpResponse.json();
          if(response.status === 200){
            console.log("SignIn",'Success');

            if(response.logs){
              setLogs(response.logs);
            }

            setLoading(false);
            return response;
          }

        } catch (error) {
          console.log(error);
        }
      
        setLoading(false);
        console.log("SignIn",'Failed');
        return response;
      };


      const rosterOn = async (  shift: Shift, area?: Area, job?: Job) => {
        console.log("rosterOn");
        if(!token || !isAuthed || !volunteer) return <VolunteerResponse>{
          status: 500,
          message: 'Not authenticated'
        };

        let response = <VolunteerResponse>{
          status: 500,
          message: 'Something went wrong, please try again.'
        }

        setLoading(true);

        try {
          const httpResponse = await fetch(`${API}/rosters/add.json`,{
            headers: {
              'Content-Type': 'application/json',
              'Authorization': JSON.stringify({bob: 'Hi',alt: altUser, id: token.id, token: token.token, pass: token.pass})
            },
            method: 'POST',
            body: JSON.stringify({
              volunteer_id: volunteer.id,
              area_id: area ? area.id : undefined,
              shift_id: shift.id,
              job_id: job ? job.id : undefined
            }),
          });
          
          if (!httpResponse.ok) {
            throw Error(httpResponse.statusText);
          }

          response = await httpResponse.json();
          if(response.status === 200){
            console.log("rosterOn",'Success');

            if(response.volunteer){
              setVolunteer(response.volunteer);
            }

            setLoading(false);
            return response;
          }

        } catch (error) {
          console.log(error);
        }
      
        setLoading(false);
        console.log("rosterOn",'Failed');
        return response;
      };


      const rosterOff = async ( roster: Roster) => {
        console.log("rosterOff");
        if(!token || !isAuthed || !volunteer) return <VolunteerResponse>{
          status: 500,
          message: 'Not authenticated'
        };

        let response = <VolunteerResponse>{
          status: 500,
          message: 'Something went wrong, please try again.'
        }

        setLoading(true);

        try {
          const httpResponse = await fetch(`${API}/rosters/delete/${roster.id}.json`,{
            headers: {
              'Content-Type': 'application/json',
              'Authorization': JSON.stringify({bob: 'Hi',alt: altUser, id: token.id, token: token.token, pass: token.pass})
            },
            method: 'GET',
          });
          
          if (!httpResponse.ok) {
            throw Error(httpResponse.statusText);
          }

          response = await httpResponse.json();
          if(response.status === 200){
            console.log("rosterOff",'Success');

            if(response.volunteer){
              setVolunteer(response.volunteer);
            }

            setLoading(false);
            return response;
          }

        } catch (error) {
          console.log(error);
        }
      
        setLoading(false);
        console.log("rosterOff",'Failed');
        return response;
      };


      const log = async (type: string, user_id: number) => {
        console.log("log", type);
        if(!token || !isAuthed) return <AuthResponce>{
          status: 500,
          message: 'Not authenticated'
        };

        if(!(type === 'Out' || type === 'In')) return <AuthResponce>{
          status: 500,
          message: 'Invalid type'
        };

        let response = <AuthResponce>{
          status: 500,
          message: 'Something went wrong, please try again.'
        }

        setLoading(true);

        try {
          const httpResponse = await fetch(`${API}/auth/logit.json`,{
            headers: {
              'Content-Type': 'application/json',
              'Authorization': JSON.stringify({bob: 'Hi',alt: altUser, id: token.id, token: token.token, pass: token.pass})
            },
            method: 'POST',
            body: JSON.stringify({type: type,user_id: user_id}),//TODO: Add gps to note??
          });
          
          if (!httpResponse.ok) {
            throw Error(httpResponse.statusText);
          }

          response = await httpResponse.json();
          if(response.status === 200){
            console.log("log",'Success');

            if(response.user){//Update user
              setUser(response.user);
            }

            setLoading(false);
            return response;
          }

        } catch (error) {
          console.log(error);
        }
      
        setLoading(false);
        console.log("log",'Failed');
        return response;
      };


      const saveUser = async (user: User) => {
        console.log("saveUser");
        if(!token || !isAuthed) return <UserResponse>{
          status: 500,
          message: 'Not authenticated'
        };

        let response = <UserResponse>{
          status: 500,
          message: 'Something went wrong, please try again.'
        }

        setLoading(true);

        try {
          const httpResponse = await fetch(`${API}/users/edit.json`,{
            headers: {
              'Content-Type': 'application/json',
              'Authorization': JSON.stringify({bob: 'Hi',alt: altUser, id: token.id, token: token.token, pass: token.pass})
            },
            method: 'POST',
            body: JSON.stringify(user),
          });
          
          if (!httpResponse.ok) {
            throw Error(httpResponse.statusText);
          }

          response = await httpResponse.json();
          if(response.status === 200){
            console.log("saveUser",'Success');

            if(response.user){//Update user
              setUser(response.user);
            }

            if(response.volunteer){//Update user
              setVolunteer(response.volunteer);
            }

            setLoading(false);
            return response;
          }

        } catch (error) {
          console.log(error);
        }
      
        setLoading(false);
        console.log("saveUser",'Failed');
        return response;
      };

      const saveVolunteer = async (volunteer: Volunteer) => {
        console.log("saveVolunteer");
        if(!token || !isAuthed) return <VolunteerResponse>{
          status: 500,
          message: 'Not authenticated'
        };

        let response = <VolunteerResponse>{
          status: 500,
          message: 'Something went wrong, please try again.'
        }

        setLoading(true);

        try {
          const httpResponse = await fetch(`${API}/volunteers/add.json`,{
            headers: {
              'Content-Type': 'application/json',
              'Authorization': JSON.stringify({bob: 'Hi',alt: altUser, id: token.id, token: token.token, pass: token.pass})
            },
            method: 'POST',
            body: JSON.stringify(volunteer),
          });
          
          if (!httpResponse.ok) {
            throw Error(httpResponse.statusText);
          }

          response = await httpResponse.json();
          if(response.status === 200){
            console.log("saveVolunteer",'Success');

            if(response.volunteer){//Update user
              setVolunteer(response.volunteer);
            }

            setLoading(false);
            return response;
          }

        } catch (error) {
          console.log(error);
        }
      
        setLoading(false);
        console.log("saveVolunteer",'Failed');
        return response;
      };

    const sendCode = async (code:string, tmpToken: Token | undefined) => {
        setLoading(true);
        try {
          const response = await fetch(`${API}/auth/add.json`,{
            method: 'POST',
            body: JSON.stringify(
              {
                code: code,
                token: tmpToken
              }
              ),
            headers: {
              'Content-Type': 'application/json'
            },
          });
          if (!response.ok) {
            throw Error(response.statusText);
          }
    
          const data = await response.json();
          console.log(data);
          if(data?.status == 200 && data?.token?.pass){
            //setIsAuthed(true); // let is auth function sort this
            setToken(data.token);
            store.set(`${STORE}token`,data.token);

            //setUser(data.user);

            store.set(`${STORE}email_token`,undefined);
            setEmailToken(undefined);

            setLoading(false);
            return true;
          }
    
        } catch (error) {
          console.log(error);
        }
    
        setLoading(false);
        return false;
    };

    const sendEmail = async (email: string, username: string) => {

        if(!email) return <AuthResponce>{
          status: 500,
          message: 'Email empty'
        };

        if(usernames.length > 0 && username.length === 0) return <AuthResponce>{
          status: 500,
          message: 'Please select username'
        };
        
        setLoading(true);
        let responce = <AuthResponce>{
          status: 500,
          message: 'No Op'
        }
        
        try {
          const response = await fetch(`${API}/auth/add.json`,{
            method: 'POST',
            body: JSON.stringify({email: email, username: username}),
            headers: {
              'Content-Type': 'application/json'
            },
          });
          if (!response.ok) {
            throw Error(response.statusText);
          }
          responce = await response.json();

          if(responce?.status == 200 && 
            responce.token && 
            !responce?.token?.pass &&
            responce.token.token.length > 0){
              console.log('email token');
            setEmailToken(responce.token);
            setUsernames([]);
            await store.set(`${STORE}email_token`,responce.token); 
          }

          if(responce?.status == 200 && responce?.token?.pass && responce?.user){
            console.log('auth user');
            setIsAuthed(true);
            setToken(responce.token);
            store.set(`${STORE}token`,responce.token);

            setUser(responce.user);

            store.set(`${STORE}email_token`,undefined);
            setEmailToken(undefined);

          }
          try{
            if(responce?.status == 201 && responce.usernames && responce.usernames.length > 0){
              setUsernames(responce.usernames);
            }
          }catch(e){
            console.log(e);
          }
          

        } catch (error) {
          console.log(error);
          responce.message = (error as Error).message;
          
        }
    
        setLoading(false);
        return responce;
    };

    const cancelEmail = () => {
      setEmailToken(undefined);
      store.set(`${STORE}email_token`,undefined);
    };

    const api: Api = {
        isAuthed,
        online,
        initalised,
        loading,
        agree,
        user,
        volunteer,
        event,
        jobs,
        jobshiftvols,
        jobvols,
        areas,
        getJobs,
        saveUser,
        saveVolunteer,
        rosterOn,
        rosterOff,
        log,
        signOut,
        signIn,
        volunteers,
        logOff,
        clearAgree,
        volunteerLookup,
        setVolunteers,
        sendCode,
        sendEmail,
        emailToken,
        usernames,
        getLogs,
        setUsernames,
        cancelEmail,
        logs,
        getShifts,
        setAltUser,
        altUser,
        areaShifts,
        isSearching
    };

    return api; 

};

export default useServerConnection;