import { IonContent, 
  IonItem, 
  IonLabel, IonList, IonPage,  
  IonFooter, IonRouterLink, IonChip, IonIcon, IonItemDivider, IonButton, useIonModal, IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonCardSubtitle } from '@ionic/react';
  
import React from 'react';

import {Api} from '../data/api'
import Moment from 'react-moment';
import User from '../model/User';
import FooterImage from '../components/FooterImage';
import Header from '../components/Header';
/*import QRCode from "react-qr-code";*/
import { call, chatboxEllipsesOutline, mail } from 'ionicons/icons';
import UserEdit from './UserEdit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faUserCircle } from '@fortawesome/free-solid-svg-icons';

const UserProfilePage: React.FC<{api: Api}> = ({api}) => {


  const [presentDetails, dismissDetails] = useIonModal(UserEdit, {
    api,
    closeCallback: () => dismissDetails()
  });

  return api.user ? <IonPage>
      <Header api={api} title='Profile' />
      <IonContent fullscreen>
        <IonContent>
          <IonCard>
            <IonCardHeader>
              <IonCardTitle><FontAwesomeIcon icon={faUserCircle} /> {api.user.firstname} {api.user.surname}</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
            <IonList>
          {api.user.email_address && api.user.email_address?.length > 0 &&
            <IonItem>
              <IonLabel>Email</IonLabel>
                  <IonRouterLink href={`mailto:${api.user.email_address}`}>
                  <IonChip>
                      <IonIcon icon={mail} color="dark" />
                      <IonLabel>{api.user.email_address}</IonLabel>
                  </IonChip>
                  </IonRouterLink>
            </IonItem>
            }
            {api.user.contact_phone_1.length > 0 &&
            <IonItem>
                  <IonLabel>Phone 1</IonLabel>
                  <IonRouterLink href={`tel:${api.user.contact_phone_1}`}>
                  <IonChip>
                      <IonIcon icon={call} color="dark" />
                      <IonLabel>{api.user.contact_phone_1}</IonLabel>
                  </IonChip>
                  </IonRouterLink>
              </IonItem>}
              {api.user.contact_phone_2 && api.user.contact_phone_2.length > 0 &&
              <IonItem>
                  <IonLabel>Phone 2</IonLabel>
                  <IonRouterLink href={`tel:${api.user.contact_phone_2}`}>
                  <IonChip>
                      <IonIcon icon={call} color="dark" />
                      <IonLabel>{api.user.contact_phone_2}</IonLabel>
                  </IonChip>
                  </IonRouterLink>
              </IonItem>
              }
              {api.user.sms.length > 0 &&
              <IonItem>
                  <IonLabel>SMS</IonLabel>
                  <IonRouterLink href={`sms://${api.user.sms}`}>
                  <IonChip>
                      <IonIcon icon={chatboxEllipsesOutline} color="dark" />
                      <IonLabel>{api.user.sms}</IonLabel>
                  </IonChip>
                  </IonRouterLink>
              </IonItem>
              }
              <IonItemDivider>Emergency Contact</IonItemDivider>
              <IonItem>
                  <IonLabel>Name</IonLabel>
                  <IonLabel>{api.user.next_of_kin}</IonLabel>
              </IonItem>
              <IonItem>
                  <IonLabel>Relationship</IonLabel>
                  <IonLabel>{api.user.next_of_kin_relationship}</IonLabel>
              </IonItem>
              <IonItem>
                  <IonLabel>Phone</IonLabel>
                  <IonRouterLink href={`tel:${api.user.next_of_kin_phone}`}>
                  <IonChip>
                      <IonIcon icon={call} color="dark" />
                      <IonLabel>{api.user.next_of_kin_phone}</IonLabel>
                  </IonChip>
                  </IonRouterLink>
              </IonItem>
              <IonButton onClick={() => presentDetails()} expand="block" color='warning' className="ion-margin-top">Edit</IonButton>
          </IonList>
            </IonCardContent>
          </IonCard>
          {api.user && api.user.shared && api.user.shared.map((user, index)=>
        <IonCard key={index}>
        <IonCardHeader color={user.latest && user.latest.type === 'In' ? 'primary' : 'default'}>
        <IonCardTitle>
          {user.firstname} {user.surname}</IonCardTitle>
          
          <IonCardSubtitle>{user.latest ? <>Signed {user.latest.type} <Moment fromNow>{user.latest.modified && user.latest.modified.substr(0, 19)}</Moment></>:<>Never signed in</>}</IonCardSubtitle>
        </IonCardHeader>
        <IonCardContent>
        <IonButton onClick={()=>api.setAltUser(user.id)} expand="full">Switch To</IonButton>
      </IonCardContent>
      </IonCard>
        )
        }          
        </IonContent>
      </IonContent>
      <IonFooter>
        <FooterImage />
      </IonFooter>
    </IonPage> : <IonPage></IonPage>
};

export default UserProfilePage;
