import { Redirect, Route } from 'react-router-dom';
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  setupIonicReact,
  useIonAlert,
  useIonLoading
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { calendarOutline, homeOutline, listOutline, personOutline, searchOutline } from 'ionicons/icons';
import Signin from './pages/Signin';
import Current from './pages/Current';
import Daktech from './pages/Daktech';
import Lookup from './pages/Lookup';
import React, { Component } from 'react';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
import './App.css';

import preval from 'preval.macro';

/* Theme variables */
import './theme/variables.css';

import useServerConnection, { Api } from './data/api'
import { useEffect } from 'react';
import LoginPage from './pages/LoginPage';
import UserEdit from './pages/UserEdit';
import VolunteerPage from './pages/Volunteer';
import JobsPage from './pages/JobsPage';
import JobDetailsPage from './pages/JobDetailsPage';
import UserProfilePage from './pages/UserProfilePage';
import Menu from './components/Menu';
import Install from './pages/Install';
import AreaDetailsPage from './pages/AreaDetailsPage';

setupIonicReact();

const App: React.FC = () => {

  const api = useServerConnection();
  const [isLoading, loadingComplete] = useIonLoading();
  const [presentUpdate] = useIonAlert();
  const [present] = useIonAlert();


  const dateTimeStamp = preval`module.exports = new Date().toLocaleString();`
  useEffect(() => {
    if (api.loading) {
      isLoading();
    } else {
      loadingComplete();
    }

  }, [api.loading]);

  useEffect(() => {
    window.addEventListener('hasUpdate', e =>
      presentUpdate({
        header: 'Update available',
        message: 'Please select update to get the latest version',
        buttons: ['Cancel', { text: 'Update', handler: (d) => window.location.reload() }],
        onDidDismiss: (e) => console.log('dismissed update'),
      })
    );
  }, []);

  return (
    <IonApp>
      <Menu api={api} />
      <IonReactRouter >
        <IonTabs>
          <IonRouterOutlet id="menuContent">
            <Route exact path="/home"
              render={props => {
                if (!api.isAuthed) return <LoginPage {...props} api={api} />;

                //If no first name then account has not been setup
                if (!api.user?.firstname) return <UserEdit api={api} />;

                //Check if volunteerd
                return api.volunteer ? <Signin api={api} /> : <VolunteerPage {...props} api={api} />
              }} />

            <Route exact path="/current">
              {api.user?.group_id !== 1 ? <Current api={api} /> : <></>}
            </Route>
            <Route exact path="/install">
              <Install api={api} />
            </Route>
            <Route exact path="/lookup">
              {api.user?.group_id !== 1 ? <Lookup api={api} /> : <></>}
            </Route>
            <Route path="/jobs/:id" render={props => { return <JobDetailsPage {...props} api={api} /> }} />
            <Route path="/areas/:id" render={props => { return <AreaDetailsPage {...props} api={api} /> }} />
            <Route exact path="/signup"
              render={props => {
                if (!api.isAuthed) return <LoginPage {...props} api={api} />;

                //If no first name then account has not been setup
                if (!api.user?.firstname) return <UserEdit api={api} />;

                //if we are here then check if volunteered
                return api.volunteer ? <JobsPage {...props} api={api} /> : <VolunteerPage api={api} />
              }} />
            <Route path="/profile" render={props => {
              if (!api.isAuthed) return <LoginPage {...props} api={api} />;

              if (!api.user?.firstname) return <UserEdit api={api} />;

              return <UserProfilePage {...props} api={api} />
            }} />
            <Route exact path="/about">
              <Daktech build={dateTimeStamp} api={api} />
            </Route>
            <Route exact path="/">
              <Redirect to="/home" />
            </Route>
          </IonRouterOutlet>
          <IonTabBar slot="bottom">
            <IonTabButton tab="home" href="/home">
              <IonIcon icon={homeOutline} />
              <IonLabel>Home</IonLabel>
            </IonTabButton>
            {api.isAuthed && api.user?.group_id !== 1 &&
              <IonTabButton tab="current" href="/current">
                <IonIcon icon={listOutline} />
                <IonLabel>Current</IonLabel>
              </IonTabButton>
            }
            {api.isAuthed && api.user?.group_id !== 1 &&
              <IonTabButton tab="lookup" href="/lookup">
                <IonIcon icon={searchOutline} />
                <IonLabel>Lookup</IonLabel>
              </IonTabButton>
            }
            <IonTabButton tab="signup" href="/signup" 
            // Todo: check with Abhi why it was put here
            // onClick={(e) => {
            //   present('Currently no jobs available, please check back soon!', [{ text: 'Ok' }]);
            // }}
            >
              <IonIcon icon={calendarOutline} />
              <IonLabel>Roster</IonLabel>
            </IonTabButton>
            <IonTabButton tab="profile" href="/profile">
              <IonIcon icon={personOutline} />
              <IonLabel>Profile</IonLabel>
            </IonTabButton>
          </IonTabBar>
        </IonTabs>
      </IonReactRouter>
    </IonApp>
  )
};

export default App;
