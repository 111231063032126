import { IonContent, 
  IonRefresher, IonRefresherContent,
  IonHeader, IonSearchbar, IonItem, IonItemOption, IonItemOptions, IonItemSliding, IonLabel, IonList, IonPage, IonTitle, IonToolbar, useIonAlert, IonFooter, IonGrid, IonCol, IonRow, IonButton, useIonModal, IonSpinner, IonText, IonBadge } from '@ionic/react';
  import { RefresherEventDetail } from '@ionic/core';
  
import React, { RefObject, useEffect, useRef, useState } from 'react';

import {Api} from '../data/api'
import User from '../model/User';
import Moment from 'react-moment';
import FooterImage from '../components/FooterImage';
import Header from '../components/Header';

import UserProfile from './UserProfile';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';

const UserListItem: React.FC<{api: Api; user: User; doSearch: ()=>void; ionList: RefObject<HTMLIonListElement>}> = ({api, user, doSearch,ionList}) => {
  const [present] = useIonAlert();

  const handleDismiss = () => {
    dismissDetails();
  };
  const doSignOut = async (user: User | undefined) => {
    ionList.current && ionList.current.closeSlidingItems();
    if(user){
      const result = await api.signOut(user);
      
      if(result.status === 200){
        handleDismiss();
        doSearch();//Success refresh
      }else{
        present(result.message, [{ text: 'Ok' }]);
      }
    }
  }
  const [presentDetails, dismissDetails] = useIonModal(UserProfile, {
    api,
    user,
    handleDismiss,
    doSignOut,
    from : "Lookup"
  });

  const doShowUser = () => {
    ionList.current && ionList.current.closeSlidingItems();
    presentDetails();
  }

  

  const doSignIn = async (user: User | undefined) => {
    ionList.current && ionList.current.closeSlidingItems();
    if(user){
      const result = await api.signIn(user);

      if(result.status === 200){
        doSearch();//Success refresh
      }else{
        present(result.message, [{ text: 'Ok' }]);
      }
    }
  }

  return <IonItemSliding >
  <IonItem  color={user.latest && user.latest.type === 'In' ? 'primary' : 'default'}>
    <div className='d-flex justify-content-between align-items-center w-100'>
      <div  className='d-flex align-items-center'>
        <IonText className='fw-bold'>
          {user.firstname} {user.surname} 
        </IonText>&nbsp;
        {user.latest &&  (<IonBadge color="secondary">{user.latest.type}</IonBadge>)}
      </div>
      {user.latest &&  (<Moment fromNow style={{fontSize: 13}} >{user.latest?.modified.substr(0, 19)}</Moment>)}
    </div>
  </IonItem>
  <IonItemOptions side="start">
     <IonItemOption onClick={() => doShowUser()} color="success"><FontAwesomeIcon icon={faEye} /></IonItemOption> 
  </IonItemOptions>
  {api.user?.group_id && api.user?.group_id > 2 && /* Make sure above area leader */
  <IonItemOptions side="end">
    {user.latest && user.latest.type === 'In' ?
     <IonItemOption onClick={() => doSignOut(user)} color="danger">Sign Out</IonItemOption>:
     <IonItemOption onClick={() => doSignIn(user)} color="success">Sign In</IonItemOption> }
  </IonItemOptions>
  }
</IonItemSliding>
}


const Current: React.FC<{api: Api}> = ({api}) => {
  
  const [searchText, setSearchText] = useState('');
  const ionList = useRef<HTMLIonListElement>(null);

  const doSearch = () =>{
    if(searchText.length > 1){
      api.volunteerLookup(searchText);
    }
  }

  useEffect(() => {
    if(searchText.length === 0){
      api.setVolunteers([]);
    }else{
      doSearch();
    }
  }, [searchText]);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    doSearch();
  }

  return (
    <IonPage>
      <Header api={api} title='Lookup'/>
      <IonContent fullscreen>
        <IonContent>
        <form onSubmit={(e) => onSubmit(e)} autoComplete="off">
          <IonSearchbar 
            value={searchText} 
            debounce={1000}
            onIonChange={e => setSearchText(e.detail.value!)}
            placeholder="Volunteer Lookup"
            ></IonSearchbar>
            </form>
        
          <IonList ref={ionList}>
          {api.isSearching && <IonItem>
        <IonLabel>Loading</IonLabel>
        <IonSpinner></IonSpinner>
      </IonItem>}
          {!api.isSearching && searchText.length > 0 && api.volunteers && api.volunteers.map((user, index: number) => <UserListItem 
            key={index} 
            api={api} 
            user={user} 
            ionList={ionList}
            doSearch={doSearch} />)}
          </IonList>
        </IonContent>
      </IonContent>
      <IonFooter>
        <FooterImage />
      </IonFooter>
    </IonPage>
  );
};

export default Current;
