import { IonContent, 
  IonRefresher, IonRefresherContent,
  IonHeader, IonItem, IonItemOption, IonItemOptions, 
  IonItemSliding, IonLabel, IonList, IonPage, IonTitle, 
  IonToolbar, useIonAlert, IonFooter, IonButton, IonIcon, IonSearchbar, useIonModal, IonText } from '@ionic/react';
  import { RefresherEventDetail } from '@ionic/core';
import React, { RefObject, useEffect, useRef, useState } from 'react';

import {Api} from '../data/api'
import Moment from 'react-moment';
import User,{VolunteerLog} from '../model/User';
import FooterImage from '../components/FooterImage';
import Header from '../components/Header';

import UserProfile from './UserProfile';
import Menu from '../components/Menu';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const UserListItem: React.FC<{api: Api; log: VolunteerLog; ionList: RefObject<HTMLIonListElement>}> = ({api, log, ionList}) => {
  const [present] = useIonAlert();

  const handleDismiss = () => {
    dismissDetails();
  };

  const doSignOut = async (user: User | undefined) => {
    ionList.current && ionList.current.closeSlidingItems();
    if(user){
      const result = await api.signOut(user);
      if(result.status !== 200){
        present(result.message, [{ text: 'Ok' }]);
      }
      handleDismiss();
    }
  }

  const [presentDetails, dismissDetails] = useIonModal(UserProfile, {
    api,
    user: log.user,
    handleDismiss,
    doSignOut,
    from : "Current"
  });

  

  const doShowUser = () => {
    ionList.current && ionList.current.closeSlidingItems();
    presentDetails();
  }

  return <IonItemSliding>
    <IonItemOptions side="start">
     <IonItemOption onClick={() => doShowUser()} color="success"><FontAwesomeIcon icon={faEye} /></IonItemOption> 
    </IonItemOptions>
    <IonItem>
      <div className='d-flex justify-content-between align-items-center w-100'>
      <div  className='d-flex align-items-center'>
        <IonText className='fw-bold'>
          {log.user?.firstname} {log.user?.surname}
        </IonText>&nbsp;
      </div>
      {true &&  (<Moment fromNow style={{fontSize: 13}} >{log.modified.substr(0, 19)}</Moment>)}
    </div>
    </IonItem>
    {api.user?.group_id && api.user?.group_id > 2 && /* Make sure above area leader */
    <IonItemOptions side="end">
      <IonItemOption onClick={() => doSignOut(log.user)} color="danger">Sign Out</IonItemOption>
    </IonItemOptions>}
  </IonItemSliding>
}


const Current: React.FC<{api: Api}> = ({api}) => {
  const [present] = useIonAlert();
  const [searchText, setSearchText] = useState('');
  const ionList = useRef<HTMLIonListElement>(null);

  const doRefresh = async (event: CustomEvent<RefresherEventDetail>) => {
    await api.getLogs();
    event.detail.complete();
  }

  useEffect(() => {
    api.getLogs();
  }, []);

  return (
    <IonPage>
      
      <Header api={api} title={`${api.logs.length} Signed in`}/>
      <IonContent fullscreen>
        <IonContent>
          <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
            <IonRefresherContent></IonRefresherContent>
          </IonRefresher>
          <IonSearchbar 
            value={searchText} 
            onIonChange={e => setSearchText(e.detail.value!)}
            placeholder="Filter list"
            ></IonSearchbar>
            <IonList ref={ionList}>
              {api.logs && api.logs.filter((log)=> `${log.user?.firstname} ${log.user?.surname}`.toLowerCase().indexOf(searchText.toLowerCase()) !== -1).map((log, index: number) => 
              <UserListItem key={index} api={api} log={log} ionList={ionList} />
            )}
          </IonList>
        </IonContent>
      </IonContent>
      <IonFooter>
        <FooterImage />
      </IonFooter>
    </IonPage>
  );
};

export default Current;
