
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonContent,
  IonList,
  IonPage,
  useIonAlert,
} from '@ionic/react';
import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import CustomInput from '../components/InputContainer'
import { yupResolver } from '@hookform/resolvers/yup';

import User, {MainUser, UserErrors, UserSchema} from '../model/User'

import {Api} from '../data/api';
import Header from '../components/Header';

const UserEdit: React.FC<{api:Api; closeCallback?: () => void}> = ({api,closeCallback}) => {

  const [presentAlert] = useIonAlert();
  const [formErrors, setFormErrors] = useState<UserErrors>();
  
  const defaultValues = {
    firstname: api.user?.firstname || "",
    surname: api.user?.surname || "",
    contact_phone_1: api.user?.contact_phone_1 || "",
    sms: api.user?.sms || "",
    next_of_kin: api.user?.next_of_kin || "",
    birth_year: api.user?.birth_year || undefined,
    gender: api.user?.gender || "",
    next_of_kin_phone: api.user?.next_of_kin_phone || "",
    next_of_kin_relationship: api.user?.next_of_kin_relationship || ""
  };

  const { register, handleSubmit, formState: { errors } } = useForm({
    defaultValues,
    resolver: yupResolver(UserSchema)});

  const onSubmit = async (data: any) => {
    
    const response = await api.saveUser(data);
    if(response?.status !== 200){
      const $message = response.message ? response.message : 'Error saving, please check inputs and try again';
      presentAlert($message, [{ text: 'Ok' }]);
      response.errors && setFormErrors(response.errors);
    }else{
      //If coming from the profile page
      if(closeCallback){
        closeCallback();
      }
    }
  };
  
  return (
    <IonPage id="view-message-page">
      <Header api={api} title='Profile'/>

      <IonContent fullscreen>
      <IonCard>
          <IonCardContent>
          
      <form onSubmit={handleSubmit(onSubmit)}>
      <IonList>
        <CustomInput type="text" 
          inputText="First Name" 
          specForm={formErrors?.firstname} 
          error={errors.firstname} 
          register={register("firstname")} ></CustomInput>

        <CustomInput type="text" 
          inputText="Surname" 
          specForm={formErrors?.surname} 
          error={errors.surname} 
          register={register("surname")} ></CustomInput>

      <CustomInput type="tel" 
          inputText="Phone" 
          specForm={formErrors?.contact_phone_1} 
          error={errors.contact_phone_1} 
          register={register("contact_phone_1")} ></CustomInput>

        <CustomInput type="select" 
          inputText="Gender" 
          list={[{value:'Male','text':'Male'},{value:'Female','text':'Female'}]}
          specForm={formErrors?.gender} 
          error={errors.gender} 
          register={register("gender")}></CustomInput>

      <CustomInput type="birthyear" 
          inputText="Birth Year" 
          specForm={formErrors?.birth_year} 
          error={errors.birth_year} 
          register={register("birth_year")}></CustomInput>

      <CustomInput type="text" 
                inputText="Emergency Contact Person" 
                specForm={formErrors?.next_of_kin} 
                error={errors.next_of_kin} 
                register={register("next_of_kin")} ></CustomInput>

      <CustomInput type="tel" 
                inputText="Emergency Contact Phone" 
                specForm={formErrors?.next_of_kin_phone} 
                error={errors.next_of_kin_phone} 
                register={register("next_of_kin_phone")} ></CustomInput>

      <CustomInput type="text" 
                inputText="Relationship to You" 
                specForm={formErrors?.next_of_kin_relationship} 
                error={errors.next_of_kin_relationship} 
                register={register("next_of_kin_relationship")} ></CustomInput>

      </IonList>

          <IonButton expand="block" type="submit" className="ion-margin-top">
            {api.user?.firstname ? 'Update' : 'Register'}
          </IonButton>
          </form>
          {closeCallback && 
          <IonButton onClick={closeCallback} expand="block" color='warning' className="ion-margin-top">Cancel</IonButton>}
          </IonCardContent>
        </IonCard>
      </IonContent>
    </IonPage>
  );
}

export default UserEdit;
