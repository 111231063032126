import { IonBadge, IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonContent, IonFooter, IonList, IonPage, useIonAlert } from '@ionic/react';
  
import React, { useEffect, useState } from 'react';

import Moment from 'react-moment';
import FooterImage from '../components/FooterImage';
import Header from '../components/Header';
import { Api } from '../data/api';
import { Area, Roster, Shift } from '../model/User';

const ShiftListItem: React.FC<{api: Api; shift: Shift, area?: Area}> = ({api, shift, area}) => {
  const [presentAlert] = useIonAlert();
  
  const start = new Date(shift.start_time);
  const end = new Date(shift.end_time);
  const today = new Date();
  const inPast = end < today;

  const sameday = start.toDateString() === end.toDateString();

  const hours = Math.abs( end.getTime() - start.getTime()) / (1000 * 60 * 60);

  const onRosterOn = async () => {
    
    const response = await api.rosterOn(shift,area);
    if(response?.status !== 200){
      const $message = response.message ? response.message : 'Error saving, please check inputs and try again';
      presentAlert($message, [{ text: 'Ok' }]);
      
    }
  };

  const onRosterOff = (roster: Roster) => {
    presentAlert({
      header: 'Are you sure you would like to remove this roster?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel'
        },
        {
          text: 'OK',
          role: 'confirm',
          handler: async () =>  {
            const response = await api.rosterOff(roster);
              if(response?.status !== 200){
                const $message = response.message ? response.message : 'Error saving, please check inputs and try again';
                presentAlert($message, [{ text: 'Ok' }]);
                
              }
          },
        },
      ]
    })
  };

  //get the current roster if rostered on
  const isRosteredOn = api.volunteer?.rosters?.find(roster => roster.shift_id === shift.id && roster.area_id === area?.id && !roster.job);

  const isBusy = api.volunteer?.rosters?.some(roster => {
    if (roster.shift_id === shift.id) {
      return true;
    }
  
    return false;
  });

  return inPast ? <></>: <IonCard>
  <IonCardHeader>
    <IonCardTitle>{shift.title}<IonBadge color="success" slot="end" >{hours} Hrs</IonBadge></IonCardTitle>
  </IonCardHeader>
  <IonCardContent>
  <Moment format="ddd Do MMM h:mm a" utc>{shift.start_time}</Moment> ~ 
{sameday ? <Moment format="h:mm a" utc>{shift.end_time}</Moment> : <Moment format="ddd Do MMM h:mm a" utc>{shift.end_time}</Moment>}

{isRosteredOn && <IonButton color='danger' expand="full"
onClick={() => onRosterOff(isRosteredOn)}>Cancel
</IonButton>}

{!isRosteredOn &&  !isBusy && <IonButton onClick={onRosterOn} expand="full" >Roster On</IonButton>}

{!isRosteredOn && isBusy && <IonButton color='success' disabled expand="full">Already rostered</IonButton>}

  </IonCardContent>
  </IonCard>
}

const AnyJobPage: React.FC<{api: Api, handleDismiss: ()=>void, area?: Area}> = ({api, handleDismiss, area}) => {
  const [shifts, setShifts] = useState<Shift[] | undefined>(undefined);
  
  useEffect(() => {
    console.log('any job page load');
    if(area){
      setShifts(api.areaShifts[area.id]);
    }
  }, []);

  return (
    <IonPage>
      <Header title='Roster for Any Job' api={api} backCallback={handleDismiss}/>
      <IonContent fullscreen>
        <IonCard>
          <IonCardHeader>
            <IonCardTitle>Any job in {area? area.title : 'any area'}</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
          {area?.description}
          </IonCardContent>
          </IonCard>
          
            {area && shifts && shifts.map((shift,key) =><ShiftListItem key={key} shift={shift} api={api} area={area}/>)}
          
        </IonContent>
      <IonFooter>
        <FooterImage />
      </IonFooter>
    </IonPage>
  );
};

export default AnyJobPage;
