import { IonContent, 
  IonItem, 
  IonLabel, IonList, IonPage,  
  IonFooter, IonRouterLink, IonChip, IonIcon, IonItemDivider, IonCard, IonCardHeader, IonCardTitle, IonCardContent, useIonAlert, IonButton, IonBackButton, IonBadge } from '@ionic/react';
  
import React, { useEffect, useState } from 'react';

import {Api} from '../data/api'
import { Area, Job, Roster, Shift } from '../model/User';
import FooterImage from '../components/FooterImage';
import Header from '../components/Header';
import Moment from 'react-moment';
import { RouteComponentProps } from 'react-router';
import JobPage from './JobPage';
import AnyJobPage from './AnyJobPage';


interface AreaDetailsPageProps
  extends RouteComponentProps<{
    id: string;
  }> {
    api: Api;
  }

const AreaDetailsPage: React.FC<AreaDetailsPageProps> = ({match, api, history}) => {
  const [theArea, setTheArea] = useState<Area>();

  const goBack = () => {history.go(-1)};

  useEffect(() => {
    console.log('page load');
    if(api.initalised && api.jobs.length === 0){
      api.getJobs();
    }

    if(api.jobs && !theArea){
      console.log('Area set');
      setTheArea(api.jobs.find((el)=> el.area_id === parseInt(match.params.id))?.area);
    }

  }, [api.initalised ,api.jobs, match.params.id]);

  return theArea ? <AnyJobPage api={api} area={theArea} handleDismiss={goBack}/> : <></>;
};

export default AreaDetailsPage;
