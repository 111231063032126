import { IonToolbar,IonTitle, IonIcon, IonMenu, IonHeader, IonContent, IonList, IonItem, IonLabel, IonMenuToggle, IonButton, IonPage } from '@ionic/react';
import React, { Component }  from 'react';

import { helpOutline, informationCircle, logOutOutline} from 'ionicons/icons';
import { Api } from '../data/api';


interface ContainerProps { 
  api: Api
}

const Menu: React.FC<ContainerProps> = ({api}) => {
  
  
  return (
    <IonMenu side='end' contentId="menuContent">
        <IonHeader>
          <IonToolbar>
            <IonTitle>Menu</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <IonList>
            <IonItem button routerLink="/about">
            <IonIcon slot="end" icon={informationCircle}></IonIcon><IonLabel>About</IonLabel>
            </IonItem>
            <IonItem button routerLink="/install">
            <IonIcon slot="end" icon={helpOutline}></IonIcon><IonLabel>Install</IonLabel>
            </IonItem>
            {api.isAuthed && <IonItem button onClick={()=> api.logOff() }>
            <IonIcon slot="end" icon={logOutOutline}></IonIcon><IonLabel>Switch User (Logoff)</IonLabel></IonItem>}
          </IonList>
        </IonContent>
      </IonMenu>
      
  );
};

export default Menu;
